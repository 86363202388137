/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(".imgLiquidFill").imgLiquid();

        $('.experiences-slider').slick({
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            adaptiveHeight: true
        });


        $('.brands-slider').slick({
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            draggable: true,
            accessibility: true,
            infinite: true, 
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 993,
                settings: {
                  arrows: false,
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  slidesToShow: 2
                }
              }
            ]
        });

        $('.open_search .fa-search').click(function(){
          $(this).parent().find('.searchbox').fadeIn();
        });

        $(document).click(function(e){
          var container = $('.searchbox');
          var container2 = $('.open_search .fa-search');

          if (!container.is(e.target) && container.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0)
          {
              container.fadeOut();
          }
        });
      
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.modal-overlay .close').click(function(){
          $('.modal-overlay').velocity('fadeOut');
          $('html').removeClass('popup-open');
        });

        $('.afspraak_btn').click(function(e){
          e.preventDefault();

          $('.modal-overlay').hide();
          $('.modal-overlay').velocity('fadeIn');
          $('html').addClass('popup-open');
        });

        $('.modal-overlay').click(function(e){
          if($(this).outerWidth() > 760){
            $('.modal-overlay').velocity('fadeOut');
            $('html').removeClass('popup-open');
          }
        });

        $('.modal-overlay .modal').click(function(e){
          e.stopPropagation();
        });

        $('.chat-btn').click(function(e){
          e.preventDefault();
        });

        function sizes() {
          if($('.container').outerWidth() > 749) {
            $('.nav_mobile').hide();
          }
        }

        $('.mobile_button').click(function(){
          var status = $(this).attr('data-status');
          if(status === 'closed') {
            $('.nav_mobile').velocity('slideDown');
            $(this).attr('data-status', 'open');
          } else {
            $('.nav_mobile').velocity('slideUp');
            $(this).attr('data-status', 'closed');
          }
        });

        $('.drop').click(function(e){
          e.preventDefault();
          var status = $(this).parent().find('ul.dropdown-menu').attr('data-status');
         
          if(status === 'closed') {
            $(this).parent().find('ul.dropdown-menu').show();
            $('.nav_mobile ul.dropdown-menu[data-status="open"]').each(function(){
              $(this).hide();
              $(this).attr('data-status', 'closed');
            });
            $(this).parent().find('ul.dropdown-menu').attr('data-status', 'open');
          } else {
            $(this).parent().find('ul.dropdown-menu').hide();
            $(this).parent().find('ul.dropdown-menu').attr('data-status', 'closed');            
          }
        });

        $('.scroll-diensten').click(function(e){
          e.preventDefault();
          var scrollT = ($('section.cards').offset().top - $('.navbar.navbar-main').outerHeight());
          $('html, body').velocity('stop').velocity('scroll', { duration: 750, offset: scrollT});
        });

        $('.faq .items .item').click(function(){
          var status = $(this).attr('data-status');
          if(status === 'closed'){
            $(this).find('.text').velocity('slideDown');
            $(this).attr('data-status', 'open').addClass('open');
          } else {
            $(this).find('.text').velocity('slideUp');
            $(this).attr('data-status', 'closed').removeClass('open');
          }
        });

        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var header = $('.header_wrap');
        var navbarHeight = header.outerHeight();

        function hasScrolled() {
            var he = header.outerHeight();
            var he_top = header.outerHeight() + $('.header_wrap .navbar.navbar-default').outerHeight();

            var st = $(this).scrollTop();
            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta) {
                return;
            }
            
            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st <= he) {
              header.velocity("stop");
              header.velocity({ opacity: 1, translateZ: 0, translateY: '0px'}, {easing: 'ease', duration: 400 });
              header.removeClass('nav_fade-in');
            } else if (st > lastScrollTop){
              // Scroll Down
              header.velocity("stop");
              header.velocity({ opacity: 0, translateZ: 0, backgroundColorAlpha: 0, translateY: '-'+ he_top + 'px'}, {easing: 'ease', duration: 400 });
              header.removeClass('nav_fade-in');
            } else {
              // Scroll Up
              if(st + $(window).height() < $(document).height()) {
                  header.velocity("stop");
                  header.velocity({ opacity: 1, translateZ: 0, translateY: '-'+ he + 'px'}, {easing: 'ease', duration: 400 });
                  header.addClass('nav_fade-in');
              }

            }
            
            lastScrollTop = st;
        }
        
        $(window).scroll(function(event){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 100);

        $('.loader').velocity('fadeOut', { delay: 200, duration: 300 });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.header_slider').slick({
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            draggable: true,
            accessibility: true,
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Js required to open the search form
$(document).ready(function(){
  var submitIcon = $('.searchbox-icon');
  var inputBox = $('.searchbox-input');
  var searchBox = $('.searchbox');
  var isOpen = false;
  submitIcon.click(function(){
      if(isOpen == false){
          inputBox.focus();
          isOpen = true;
      } else {
          inputBox.focusout();
          isOpen = false;
      }
  });  
   submitIcon.mouseup(function(){
          return false;
      });
  searchBox.mouseup(function(){
          return false;
      });
  $(document).mouseup(function(){
          if(isOpen == true){
              $('.searchbox-icon').css('display','block');
              submitIcon.click();
          }
      });
});
 
